/*DISTRIBUCION COLUMNAS AhmGrid
by Adrián Herrero Montoya

@media (min-width: 1000px) {
	.box {
        --paddingX: 20px;
        --paddingY: 20px;
        --margenesBox: 30px;
        --margenesBox2: 5px;
		--heightBoxXXSmall: 165px;
		--heightBoxXSmall: 235px;
		--heightBoxSmall: 300px;
		--heightBoxMedium: 370px;
		--heightBoxLarge: 400px;
	}
}
@media (max-width: 1000px) {
	.box {
        --paddingX: 10px;
        --paddingY: 10px;
        --margenesBox: 100px;
        --margenesBox2: 100px;
		--heightBoxXXSmall: 165px;
		--heightBoxXSmall: 235px;
		--heightBoxSmall: 300px;
		--heightBoxMedium: 370px;
		--heightBoxLarge: 400px;
	}
}

*/
/*-----------------------------GENERALES-----------------------------*/ 

*[class*="dst"] {float:left;width: 100%;position: relative;}
.box {position: relative;}
.box:not([class*="dst"]):not(.nostyle) {padding: var(--paddingY) var(--paddingX);}


 /*-----------------------------DESKTOP-----------------------------*/ 
@media (min-width: 1000px) {
 *[class*="dst"] > .box {float:left; margin-right: var(--margenesBox);margin-bottom: var(--margenesBox)}
 *[class*="dst"].m2 > .box {float:left; margin-right: var(--margenesBox2);margin-bottom: var(--margenesBox2)}
 .box[class*="dst"] {margin-bottom: 0; }
 

        /*UNA FILA MARGEN TIPO 1*/
            /**/
            .dst6-3-3 > .box {width: calc(25% - calc(var(--margenesBox) * 3 / 4))}
            .dst6-3-3 > .box:first-child{width: calc(2 * (25% - calc(var(--margenesBox) * 3 / 4)) + var(--margenesBox));}
            .dst6-3-3 > .box:nth-child(3n) {margin-right: 0;}
            /**/
            .dst3-3-3-3 > .box {width: calc(25% - calc(var(--margenesBox) * 3 / 4));}
            .dst3-3-3-3 > .box:nth-child(4n) {margin-right: 0;}
            /**/    
            .dst4-4-4 > .box {width: calc(33.333% - calc(var(--margenesBox) * 2 / 3));}
            .dst4-4-4 > .box:nth-child(3n) {margin-right: 0;}
            /**/    
            .dst3-3-6 > .box {width: calc(25% - calc(var(--margenesBox) * 3 / 4));}
            .dst3-3-6 > .box:nth-child(3n) {width: calc(2 * (25% - 18px) + var(--margenesBox));margin-right: 0;}
            /**/
            .dst4-8 > .box:nth-child(odd) {width: calc(33.333% - calc(var(--margenesBox) * 1 / 2)); margin-right: var(--margenesBox)}
            .dst4-8 > .box:nth-child(even) {width: calc(66.666% - calc(var(--margenesBox) * 1 / 2));margin-right: 0;}
            /**/
            .dst7-5 > .box:nth-child(even) {width: calc(41.666% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dst7-5 > .box:nth-child(odd) {width: calc(58.333% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dst8-4 > .box:nth-child(even) {width: calc(33.333% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dst8-4 > .box:nth-child(odd) {width: calc(66.666% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dst3-9 > .box:nth-child(even) {width: calc(75% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dst3-9 > .box:nth-child(odd) {width: calc(25% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dst2-10 > .box:nth-child(even) {width: calc(83.333% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dst2-10 > .box:nth-child(odd) {width: calc(16.666% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dst9-3 > .box:nth-child(even) {width: calc(25% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dst9-3 > .box:nth-child(odd) {width: calc(75% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/ 
            .dst10-2 > .box:nth-child(even) {width: calc(16.666% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dst10-2 > .box:nth-child(odd) {width: calc(83.333% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dst6-6 > .box {width:calc(50% - calc(var(--margenesBox) / 2))}
            .dst6-6 > .box:nth-child(2n) {margin-right: 0;}
            /**/
            .dst12 > .box {width: 100%; margin-right: 0;}

            /*UNA FILA MARGEN TIPO 2*/
           /**/
           .dst6-3-3.m2 > .box {width: calc(25% - calc(var(--margenesBox2) * 3 / 4))}
           .dst6-3-3.m2 > .box:first-child{width: calc(2 * (25% - calc(var(--margenesBox2) * 3 / 4)) + var(--margenesBox2));}
           .dst6-3-3.m2 > .box:nth-child(3n) {margin-right: 0;}
           /**/
           .dst3-3-3-3.m2 > .box {width: calc(25% - calc(var(--margenesBox2) * 3 / 4));}
           .dst3-3-3-3.m2 > .box:nth-child(4n) {margin-right: 0;}
           /**/    
           .dst4-4-4.m2 > .box {width: calc(33.333% - calc(var(--margenesBox2) * 2 / 3));}
           .dst4-4-4.m2 > .box:nth-child(3n) {margin-right: 0;}
           /**/    
           .dst3-3-6.m2 > .box {width: calc(25% - calc(var(--margenesBox2) * 3 / 4));}
           .dst3-3-6.m2 > .box:nth-child(3n) {width: calc(2 * (25% - 18px) + var(--margenesBox2));margin-right: 0;}
           /**/
           .dst4-8.m2 > .box:nth-child(odd) {width: calc(33.333% - calc(var(--margenesBox2) * 1 / 2)); margin-right: var(--margenesBox2)}
           .dst4-8.m2 > .box:nth-child(even) {width: calc(66.666% - calc(var(--margenesBox2) * 1 / 2));margin-right: 0;}
           /**/
           .dst7-5.m2 > .box:nth-child(even) {width: calc(41.666% - calc(var(--margenesBox2) * 1 / 2));margin-right: 0; }
           .dst7-5.m2 > .box:nth-child(odd) {width: calc(58.333% - calc(var(--margenesBox2) * 1 / 2));margin-right: var(--margenesBox2)}
           /**/
           .dst8-4.m2 > .box:nth-child(even) {width: calc(33.333% - calc(var(--margenesBox2) * 1 / 2));margin-right: 0; }
           .dst8-4.m2 > .box:nth-child(odd) {width: calc(66.666% - calc(var(--margenesBox2) * 1 / 2));margin-right: var(--margenesBox2)}
            /**/
            .dst3-9.m2 > .box:nth-child(even) {width: calc(75% - calc(var(--margenesBox2) * 1 / 2));margin-right: 0; }
            .dst3-9.m2 > .box:nth-child(odd) {width: calc(25% - calc(var(--margenesBox2) * 1 / 2));margin-right: var(--margenesBox2)}
            /**/
            .dst2-10.m2 > .box:nth-child(even) {width: calc(83.333% - calc(var(--margenesBox2) * 1 / 2));margin-right: 0; }
            .dst2-10.m2 > .box:nth-child(odd) {width: calc(16.666% - calc(var(--margenesBox2) * 1 / 2));margin-right: var(--margenesBox2)}
            /**/
            .dst9-3.m2 > .box:nth-child(even) {width: calc(25% - calc(var(--margenesBox2) * 1 / 2));margin-right: 0; }
            .dst9-3.m2 > .box:nth-child(odd) {width: calc(75% - calc(var(--margenesBox2) * 1 / 2));margin-right: var(--margenesBox2)}
            /**/ 
            .dst10-2.m2 > .box:nth-child(even) {width: calc(16.666% - calc(var(--margenesBox2) * 1 / 2));margin-right: 0; }
            .dst10-2.m2 > .box:nth-child(odd) {width: calc(83.333% - calc(var(--margenesBox2) * 1 / 2));margin-right: var(--margenesBox2)}
            /**/
           .dst6-6.m2 > .box {width: calc(50% - calc(var(--margenesBox2) / 2));}
           .dst6-6.m2 > .box:nth-child(2n) {margin-right: 0;}
           /**/
           .dst12.m2 > .box {width: 100%; margin-right: 0;}


        /*DOS FILAS*/
            /**/
            .dst12-6-6 > .box {width: calc(2 * (25% - calc(var(--margenesBox) * 3 / 4)) + var(--margenesBox));}
            .dst12-6-6 > .box:first-child{width: 100%; margin-right: 0;}
            .dst12-6-6 > .box:nth-child(3n) {margin-right: 0;}
            /**/
            .dst12-4-4-4 > .box {width: calc(33.333% - calc(var(--margenesBox) * 2 / 3));}
            .dst12-4-4-4 > .box:first-child{width: 100%; margin-right: 0;}
            .dst12-4-4-4 > .box:nth-child(4n) {margin-right: 0;}
        }


/*-----------------------------MOVILES-----------------------------*/
		@media (max-width:1000px) {

        *[class*="dst"] > .box {float:left; margin-right: var(--margenesBox);margin-bottom: var(--margenesBox); min-width: unset;}
        *[class*="dst"].m2 > .box {float:left; margin-right: var(--margenesBox2);margin-bottom: var(--margenesBox2); min-width: unset;}
		.box[class*="dst"] {margin-bottom: 0;}
		.box:not([class*="dstm"]) {width: 100%;}


		/*UNA FILA*/
            /**/
            .dstm6-3-3 > .box {width: calc(25% - calc(var(--margenesBox) * 3 / 4))}
            .dstm6-3-3 > .box:first-child{width: calc(2 * (25% - calc(var(--margenesBox) * 3 / 4)) + var(--margenesBox));}
            .dstm6-3-3 > .box:nth-child(3n) {margin-right: 0;}
            /**/
            .dstm3-3-3-3 > .box {width: calc(25% - calc(var(--margenesBox) * 3 / 4));}
            .dstm3-3-3-3 > .box:nth-child(4n) {margin-right: 0;}
            /**/    
            .dstm4-4-4 > .box {width: calc(33.333% - calc(var(--margenesBox) * 2 / 3));}
            .dstm4-4-4 > .box:nth-child(3n) {margin-right: 0;}
            /**/    
            .dstm3-3-6 > .box {width: calc(25% - calc(var(--margenesBox) * 3 / 4));}
            .dstm3-3-6 > .box:nth-child(3n) {width: calc(2 * (25% - 18px) + var(--margenesBox));margin-right: 0;}
            /**/
            .dstm4-8 > .box:nth-child(odd) {width: calc(33.333% - calc(var(--margenesBox) * 1 / 2)); margin-right: var(--margenesBox)}
            .dstm4-8 > .box:nth-child(even) {width: calc(66.666% - calc(var(--margenesBox) * 1 / 2));margin-right: 0;}
            /**/
            .dstm7-5 > .box:nth-child(even) {width: calc(41.666% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dstm7-5 > .box:nth-child(odd) {width: calc(58.333% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dstm8-4 > .box:nth-child(even) {width: calc(33.333% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dstm8-4 > .box:nth-child(odd) {width: calc(66.666% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dstm3-9 > .box:nth-child(even) {width: calc(25% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dstm3-9 > .box:nth-child(odd) {width: calc(75% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dstm2-10 > .box:nth-child(even) {width: calc(25% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dstm2-10 > .box:nth-child(odd) {width: calc(75% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dstm9-3 > .box:nth-child(even) {width: calc(25% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dstm9-3 > .box:nth-child(odd) {width: calc(75% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/ 
            .dstm10-2 > .box:nth-child(even) {width: calc(20% - calc(var(--margenesBox) * 1 / 2));margin-right: 0; }
            .dstm10-2 > .box:nth-child(odd) {width: calc(80% - calc(var(--margenesBox) * 1 / 2));margin-right: var(--margenesBox)}
            /**/
            .dstm6-6 > .box {width: calc(50% - calc(var(--margenesBox) / 2))}
            .dstm6-6 > .box:nth-child(2n) {margin-right: 0;}
            /**/
            .dstm12 > .box {width: 100%; margin-right: 0;}

        /*DOS FILAS*/
            /**/
            .dstm12-6-6 > .box {width: calc(2 * (25% - calc(var(--margenesBox) * 3 / 4)) + var(--margenesBox));}
            .dstm12-6-6 > .box:first-child{width: 100%; margin-right: 0;}
            .dstm12-6-6 > .box:nth-child(3n) {margin-right: 0;}
            /**/
            .dstm12-4-4-4 > .box {width: calc(33.333% - calc(var(--margenesBox) * 2 / 3));}
            .dstm12-4-4-4 > .box:first-child{width: 100%; margin-right: 0;}
            .dstm12-4-4-4 > .box:nth-child(4n) {margin-right: 0;}

				}


        /*TAMAÑOS*/
		.box.xxsmall {height: var(--heightBoxXXSmall);}
		.box.xsmall {height: var(--heightBoxXSmall);}
			.box.xsmall-xsmall {height: calc(var(--heightBoxXSmall) * 2 + var(--margenesBox));}
		.box.small {height: var(--heightBoxSmall);}
			.box.small-small {height: calc(var(--heightBoxSmall) * 2 + var(--margenesBox));}
			.box.small-xsmall {height: calc(var(--heightBoxSmall) + var(--heightBoxXSmall) + var(--margenesBox));}
			.box.small-xxsmall {height: calc(var(--heightBoxSmall) + var(--heightBoxXXSmall) + var(--margenesBox));}
		.box.medium {height: var(--heightBoxMedium)}
			.box.medium-medium {height: calc(var(--heightBoxMedium) * 2 + var(--margenesBox));}
		.box.large {height: var(--heightBoxLarge)}
			.box.large-large {height: calc(var(--heightBoxLarge) * 2 + var(--margenesBox));}